import 'bootstrap';
import './sass/main.scss';
import 'owl.carousel.es6';

var numbersUpdated = false;

$(function () {

    // Mobile menu
    {
        const body = $('body'),
            mainContainer = $('.main-container');

        $('.mobile-menu-toggle').on('click', function () {
            mainContainer.toggleClass('show-menu');
        });

        $('.mobile-menu-toggle, .circle, .nav-bar, .menu-link, .header').on('click', function (event) {
            event.stopPropagation();
        });

        body.on('click', '.main-container.show-menu', function () {
            mainContainer.removeClass("show-menu");
        });
    }

    // Buttons "back to top" and "share"
    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 300) {
            $('body').addClass('scroll');
        } else {
            $('body').removeClass('scroll');
        }
    }).trigger('scroll');

    $('.back-to-top').on('click', function () {
        $("html, body").animate({
            scrollTop: 0
        }, 500);
    });
    
    //youtube video
    $(function () {
        const video = $('.youtube-video'),
            widthVideo = video.parent().innerWidth(),
            heightVideo = widthVideo * 0.5617;

        video.css({ width: widthVideo + 'px', height: heightVideo + 'px' });
    });

    // Owl-carousel
    $('.home .wrapp-videos, .blog .wrapp-videos').owlCarousel({
        nav: true,
        navText: [],
        margin: 30,
        center: true,
        loop:true,
        dots: true,
        //autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            670: {
                items: 2
            }
        }
    });
    $('.wrapp-reviews').owlCarousel({
        nav: true,
        navText: [],
        center: true,
        loop:true,
        dots: true,
        //autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            670: {
                items: 2
            }
        }
    });

    // Video, img, review-comment in popup
    $('#video-modal').on('show.bs.modal', function (event) {
        const $element = $(event.relatedTarget);
        const video = $element.data('video');
        const modal = $(this);
        const isParams = video.indexOf('?') !== -1;
        const src = video + (isParams ? '&' : '?') + 'autoplay=true';
        modal.find('iframe').attr('src', src);
    });

    $('#review-modal').on('show.bs.modal', function (event) {
        const $review = $(event.relatedTarget).closest('.review');
        const $user = $review.find('.user').html();
        const $text = $review.find('.review-text').html();
        const $modal = $(this);
        $modal.find('#user').html($user);
        $modal.find('#review-text').html($text);
    });

    $('#image-modal').on('show.bs.modal', function (event) {
        const $element = $(event.relatedTarget).children('img');
        const src = $element.attr('src');
        const modal = $(this);
        modal.find('img').attr('src', src);
    });

    $('#video-modal').on('hide.bs.modal', function () {
        var modal = $(this);
        modal.find('iframe').attr('src', '');
    });
    $('#review-modal').on('hide.bs.modal', function () {
        const $modal = $(this);
        $modal.find('#user').html('');
        $modal.find('#review-text').html('');
    });
    $('#image-modal').on('hide.bs.modal', function () {
        var modal = $(this);
        modal.find('img').attr('src', '');
    });


    if ($('#odometer-numbers').length) {
        $(window).on('scroll', updateNumbers);
    }

    // Team page popups

    const workerCloser = function () {
        if ($('.active-worker')) {
            $('.active-worker').css('transform', 'translateX(0)');
            $('.active-worker').unbind('click').on('click', workerOpener);
            $('.active-worker').removeClass('active-worker');
        }
    };
    const workerOpener = function (event) {
        workerCloser();
        const worker = $(this);
        worker.css('transform', 'translateX(' + (worker.parent().offset().left - worker.offset().left + parseInt(worker.css("marginLeft"), 10)) + 'px)');
        worker.addClass('active-worker');
        worker.off('click');
    };

    $('.worker-wrapp').on('click', workerOpener);
    $('.close-popup').on('click', function (event) {
        event.stopPropagation();
        workerCloser();
    });

    // Accordion
    {
        ! function (i) {
            var o, n;
            i(".title_block").on("click", function () {
                o = i(this).parents(".accordion_item"), n = o.find(".info"),
                    o.hasClass("active_block") ? (o.removeClass("active_block"),
                        n.slideUp()) : (o.addClass("active_block"), n.stop(!0, !0).slideDown(),
                            o.siblings(".active_block").removeClass("active_block").children(
                                ".info").stop(!0, !0).slideUp())
            })
        }(jQuery);

        if ($(window).width() < 500) {
            ! function (i) {
                var o, n;
                i(".title-video").on("click", function () {
                    o = i(this).parents(".video"), n = o.find(".wrapp-video"),
                        o.hasClass("active_block") ? (o.removeClass("active_block"),
                            n.slideUp()) : (o.addClass("active_block"), n.stop(!0, !0).slideDown(),
                                o.siblings(".active_block").removeClass("active_block").children(
                                    ".wrapp-video").stop(!0, !0).slideUp())
                })
            }(jQuery);
        }
    }


    // journal-reviews page

     $('.open-sing-in').on('click', function () {
        $('html').addClass('popup-opened');
        $('body').addClass('popup-form-opened popup-sing-opened');
        });
        /*$('.popup-sing-in').on('click', function (event) {
          $('body').addClass('popup-thanks-opened');
          event.stopPropagation();
        });*/
        /*$('.popup-thanks').on('click', function (event) {
          $('body').addClass('popup-form-opened');
          event.stopPropagation();
        });*/
        $('.popup-form').on('click', function (event) {//$('body').addClass('popup-thanks-opened');
          //event.stopPropagation();
        });
        $('.popup-form .close').on('click', function () {
          $('body').removeClass('popup-form-opened popup-thanks-opened');
          $('html').removeClass('popup-opened');
        });
        $('.category-wrapp').on('click', function ($event) {
          $($event.currentTarget).toggleClass('open');
        });
        $('.categories-list .category-link').on('click', function ($event) {
          $event.preventDefault();
          $('.categories-list .category-item').removeClass('active');
          $($event.currentTarget).parent().toggleClass('active');
          checkActiveCategory($event.currentTarget,  $('.open-category .category-name'));
        });
        let activeCategory = document.querySelector('.categories-list .category-item.active');

        if (activeCategory) {
          checkActiveCategory(activeCategory,  $('.open-category .category-name'));
        }
    
        $('.theme-list .theme-link').on('click', function ($event) {
          $event.preventDefault();
          $('.theme-list .theme-item').removeClass('active');
          $($event.currentTarget).parent().toggleClass('active');
          checkActiveCategory($event.currentTarget,  $('.open-theme .theme-name'));
        });
        let activeTheme = document.querySelector('.theme-list .theme-item.active');

        if (activeTheme) {
          checkActiveCategory(activeTheme,  $('.open-theme .theme-name'));
        }
    
        $('.theme-wrapp').on('click', function ($event) {
          $($event.currentTarget).toggleClass('open');
        });

    

    $(document).ready(function () {
        $('#trainings-completed').multiselect({
            /*buttonText: function (options, select) {
              //  return 'Пройденные тренинги:';
            },*/
            buttonTitle: function (options, select) {
                var labels = [];
                options.each(function () {
                    labels.push($(this).text());
                });
                return labels.join(' - ');
            },
            enableClickableOptGroups: false,
             nonSelectedText: 'Пройденные тренинги:',
            onChange: function (option, checked, select) {
                var opselected = $(option).val();
                if (checked == true) {
                    if (opselected == '1') {
                        $('.training', $('#trainings-completed')).each(function (element) {
                            $(this).removeAttr('selected').prop('selected', false);
                            $(this).prop('disabled', true);
                        });
                    }
                }
                if (checked == false) {
                    if (opselected == '1') {
                        $('.training', $('#trainings-completed')).each(function (element) {
                            $(this).prop('disabled', false);
                        });
                    }
                }

                $("#trainings-completed").multiselect('refresh');
            }
        });


        $('#filter-sort').multiselect({
            nonSelectedText: 'Без сортировки:',
            onChange: function (option, checked) {
                var values = [];
                $('#filter-sort option').each(function () {
                    if ($(this).val() !== option.val()) {
                        values.push($(this).val());
                    }
                });

                $('#filter-sort').multiselect('deselect', values);
            }
        });
    });

});

// Map

var App = App || {};
App.GoogleMaps = (function () {

    var init = function () {

        const kyivPos = {
            lat: 50.4022142,
            lng: 30.5208327
        };

        const mapStyle = [
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": "-32"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": "13"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": "16"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#eb3b19"
                    },
                    {
                        "lightness": "0"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": "19"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": "0"
                    },
                    {
                        "saturation": "0"
                    },
                    {
                        "gamma": "1.00"
                    },
                    {
                        "weight": "0.01"
                    }
                ]
            }
        ];

        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 9,
            center: kyivPos,
            styles: mapStyle,
            disableDefaultUI: true
        });
        var marker = new google.maps.Marker({
            position: kyivPos,
            map: map,
            icon: '../../assets/img/google-map-marker.svg'
        });

    };

    return {
        init: init
    };

})();

window.App = App;

function isInView(elem) {
    return ($(elem).offset().top + ($(elem).height() / 3)) - $(window).scrollTop() < $(elem).height();
}

function updateNumbers() {
    if (isInView($('#odometer-numbers'))) {
        var numbers = document.querySelectorAll("#odometer-numbers .quantity span");
        if (numbers && numbers.length) {
            $(window).off('scroll', updateNumbers);
            numbers.forEach(numberEl => {
                var number = numberEl.innerText.replace(' ', '') * 1;
                setTimeout(() => {
                    var od = new Odometer({
                        el: numberEl,
                        value: numberEl.innerText.replace(' ', '') * 1,
                        format: 'd ddd',
                        theme: 'minimal'
                    });
                    od.update(number);
                }, 20);
                numberEl.innerText = 0;
            });
        }
    }
}

function checkActiveCategory(target, selectedText) {
    if (target) {
        let activeCategory = $(target),
        link = activeCategory.attr('href');
        const categoryName = activeCategory.text();
        
       
       selectedText.text(categoryName);
         
        console.log(link); 
        if(link) { window.location.href = link; }
    }
}
